import React, { useEffect, useState } from "react";
import { useTheme } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import Alert from "./Alert";
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const ageOptions = ['Under 18', '18-21', '22-24', '25-34', '35-44', '45-54', '55-64', 'Age 65 or older'];
export default function SurveyAboutYou(props) {
    const [age, setAge] = useState("Under 18");
    const [gender, setGender] = useState("");
    const [genderValue, setGenderValue] = useState("");
    const [error, setError] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    useEffect(() => {
        setAge(props.age);
        setGender(props.gender);
        setGenderValue(props.genderValue);
    }, [props.age, props.gender, props.genderValue]);
    const theme = useTheme();

    const handleNext = () => {
        if (gender === "" || (gender === "Prefer to self describe as" && genderValue === "")) {
            setError("Please specify a gender");
            setSnackbarOpen(true);
            return;
        }
        props.handleNext(age, gender, genderValue);
    };

    const getButtonVariant=()=> {
        if (gender === "" || (gender === "Prefer to self describe as" && genderValue === "")) {
            return "outlined";
        }
        return "contained";
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setError("");
        setSnackbarOpen(false);
    };

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography variant={"h4"} style={{ color: theme.colorGray, fontWeight: 500 }}>ABOUT YOU</Typography>
            </div>
            <div>
                <Typography variant={"h5"} style={{ marginTop: theme.spacing(2) }}>
                    What is your age?
                </Typography>
                <TextField
                    select={true}
                    value={age}
                    onChange={(e) => setAge(e.target.value)}
                    variant={"outlined"}
                    style={{ minWidth: 200 }}
                    margin={'normal'}
                >
                    {ageOptions.map(value => <MenuItem key={value} value={value}>{value}</MenuItem>)}
                </TextField>
            </div>
            <div>
                <Typography variant={"h5"} style={{ marginTop: theme.spacing(2) }}>
                    Sex/Gender?
                </Typography>
                <RadioGroup aria-label="gender" name="gender1" value={gender}
                    onChange={(e) => setGender(e.target.value)}>
                    <FormControlLabel value="Male" control={<Radio />} label="Male" />
                    <FormControlLabel value="Female" control={<Radio />} label="Female" />
                    <FormControlLabel value="Prefer not to say" control={<Radio />} label="Prefer not to say" />
                    <FormControlLabel value="Prefer to self describe as" control={<Radio />}
                        label="Prefer to self describe as " />
                </RadioGroup>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <TextField
                        variant="outlined"
                        margin="dense"
                        style={{ minWidth: 200, marginLeft: 30 }}
                        value={genderValue}
                        onChange={(e) => setGenderValue(e.target.value)}
                        disabled={gender !== "Prefer to self describe as"}
                    />
                    <span>&nbsp; (please specify)</span>
                    <Tooltip title="non-binary, gender-fluid, agender, etc.">
                        <InfoOutlinedIcon />
                    </Tooltip>
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: theme.spacing(1),
                    marginBottom: theme.spacing(10)
                }}>
                    <Button variant={"outlined"} color={"primary"} onClick={() => props.setStep(3)}>
                        Back
                    </Button>
                    <Button variant={getButtonVariant()} color={"primary"} onClick={handleNext}
                        style={{ marginLeft: theme.spacing(3) }}>
                        Next
                    </Button>
                </div>
                <Snackbar open={snackbarOpen} onClose={handleClose} autoHideDuration={3000}>
                    <Alert severity={"error"}>{error}</Alert>
                </Snackbar>
            </div>
        </div>
    )
}