import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import { useTheme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import Alert from "./Alert";
import TextField from "@material-ui/core/TextField";


export default function SurveyContact(props) {
    const [recentCovidInteraction, setRecentCovidInteraction] = useState("");
    const [recentCovidInteractionPositive, setRecentCovidInteractionPositive] = useState("");
    const [error, setError] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [recentCovidInteractionPositiveNumber, setrecentCovidInteractionPositiveNumber] = useState(null);
    useEffect(() => {
        setRecentCovidInteraction(props.recentCovidInteraction);
        setRecentCovidInteractionPositive(props.recentCovidInteractionPositive);
    }, [props.recentCovidInteraction, props.recentCovidInteractionPositive]);
    const theme = useTheme();
  const handleNext = () => {
    if (recentCovidInteraction === "" || recentCovidInteractionPositive === "") {
      setError("Information about your interaction with any COVID patient is required");
      setSnackbarOpen(true);
      return;
    }
    if (recentCovidInteractionPositive === "Yes") {
      if (!recentCovidInteractionPositiveNumber) {
        setError("Must provide number of people interacted with that had symptoms.");
        setSnackbarOpen(true);
        return;
      }
      let number = Number(recentCovidInteractionPositiveNumber);
      if ((number % 1) !== 0) {
        setError("Number of people interacted with that had symptoms must not be a decimal number");
        setSnackbarOpen(true);
        return;
      }
      if (number <= 0) {
        setError("Number of people interacted with that had symptoms must be greater than 0");
        setSnackbarOpen(true);
        return;
      }
    }
    props.handleNext(recentCovidInteraction, recentCovidInteractionPositive, recentCovidInteractionPositiveNumber);
  };
  const getButtonVariant = () => {
    if (recentCovidInteraction === "" || recentCovidInteractionPositive === "") {
      return "outlined";
    }
    if (recentCovidInteractionPositive === "Yes") {
      if (!recentCovidInteractionPositiveNumber) {
        return "outlined";
      }
      let number = Number(recentCovidInteractionPositiveNumber);
      if ((number % 1) !== 0) {
        return "outlined";
      }
      if (number <= 0) {
        return "outlined";
      }
    }
    return "contained"
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError("");
    setSnackbarOpen(false);
  };

  return (
    <div style={{display: "flex", flexDirection: "column"}}>
      <Typography variant={"h5"}>
        In the past 14 days, have you had any contact with someone who tested positive for COVID-19?
      </Typography>
      <RadioGroup aria-label="recentCovidInteraction" name="recentCovidInteraction1"
                  value={recentCovidInteraction}
                  onChange={(e) => setRecentCovidInteraction(e.target.value)}>
        <FormControlLabel value="Yes" control={<Radio/>} label="Yes"/>
        <FormControlLabel value="No" control={<Radio/>} label="No"/>
      </RadioGroup>
      <Typography variant={"h5"} style={{marginTop: theme.spacing(2)}}>
        Did you come across anyone with COVID-19 symptoms in the past 14 days?
      </Typography>
      <RadioGroup aria-label="recentCovidInteractionPositive" name="recentCovidInteractionPositive1"
                  value={recentCovidInteractionPositive}
                  onChange={(e) => {
                    setRecentCovidInteractionPositive(e.target.value)
                  }}>
        <FormControlLabel value="Yes" control={<Radio/>} label="Yes"/>
        <FormControlLabel value="No" control={<Radio/>} label="No"/>
      </RadioGroup>
      {
        recentCovidInteractionPositive === "Yes" &&
        <div>
          <Typography variant={"h5"} style={{marginTop: theme.spacing(2)}}>
           Approximately how many such people did you come across?
          </Typography>
          <TextField
            type={"number"}
            variant="outlined"
            margin="normal"
            value={recentCovidInteractionPositiveNumber}
            onChange={e => {
              setrecentCovidInteractionPositiveNumber(e.target.value)
            }}
            style={{minWidth: 200}}
          />
        </div>
      }
      <div style={{display: "flex", justifyContent: "flex-end", marginTop: theme.spacing(1)}}>
        <Button variant={"outlined"} color={"primary"} onClick={() => props.setStep(3)}>
          Back
        </Button>
        <Button variant={getButtonVariant()} color={"primary"} style={{marginLeft: theme.spacing(3)}}
                onClick={handleNext}>
          Next
        </Button>
      </div>
      <Snackbar open={snackbarOpen} onClose={handleClose} autoHideDuration={3000}>
        <Alert severity={"error"}>{error}</Alert>
      </Snackbar>
    </div>
  )
}