import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { useTheme, makeStyles } from "@material-ui/styles";
import Survey from "./Survery";
import DialogContent from "@material-ui/core/DialogContent";
import SurveyTested from "./SurveyTested";
import SurveyUnderlying from "./SurveyUnderlying";
import SurveyHousehold from "./SurveyHousehold";
import SurveyContact from "./SurveyContact";
import SurveyAboutYou from "./SurveyAboutYou";
import SurveyLocation from "./SurveyLocations";
import SurveyCounty from "./SurveyCounty";
import SurveyProtection from "./SurveyProtection";
import { http } from "../utils/http";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import Alert from "./Alert";

const useStyles = makeStyles({
    root: {
        '& .MuiDialog-container': {
            height: 'auto'
        },
        '& .MuiBackdrop-root': {
            position: 'relative'
        }
    }
})

const symptomsMap = {
    noSymptoms: "No symptoms",
    fever: 'Fever',
    cough: 'Cough (new or worsening)',
    soarThroat: 'Sore throat (new)',
    runnyNose: 'Runny nose',
    shortnessOfBreath: "Shortness of breath/trouble breathing (new/worsening)",
    headache: 'Headache',
    diarrhea: 'Diarrhea',
    lossOfSmell: 'Loss of sense of smell',
    lossOfTaste: 'Loss of sense of taste',
    nauseaOrVomiting: 'Nausea/vomiting',
    musclePain: "Muscle aches",
    chills: 'Chills',
    abdominalPain: "Abdominal pain",
};
const underlyingConditionsMap = {
    asthma: "Asthma",
    lungDisease: "Chronic Lung Disease",
    hyperTension: "Hypertension",
    diabetes: "Diabetes",
    heartDisease: "Heart Disease",
    obesity: "Obesity i.e. body-mass-index >30",
    immunocomprosied: "Immunocompromised (e.g. due to HIV/chemotherapy)",
}
export default function SurveyForm(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [step, setStep] = useState(0);
    const [symptomStartDate, setSymptomStartDate] = useState("");
    const [symptoms, setSymptoms] = useState({
        noSymptoms: false,
        fever: false,
        cough: false,
        soarThroat: false,
        runnyNose: false,
        shortnessOfBreath: false,
        headache: false,
        diarrhea: false,
        lossOfSmell: false,
        lossOfTaste: false,
        nauseaOrVomiting: false,
        musclePain: false,
        chills: false,
        abdominalPain: false
    });
    const [tested, setTested] = useState("");
    const [testResults, setTestResults] = useState("");
    const [testDate, setTestDate] = useState("");
    const [underlyingConditions, setUnderlyingConditions] = useState({
        asthma: false,
        lungDisease: false,
        hyperTension: false,
        diabetes: false,
        heartDisease: false,
        obesity: false,
        immunocomprosied: false,
    });
    const [householdCase, setHouseholdCase] = useState("");
    const [numberOfhouseholdPeople, setHouseholdPeople] = useState("");
    const [recentCovidInteraction, setRecentCovidInteraction] = useState("");
    const [recentCovidInteractionPositive, setRecentCovidInteractionPositive] = useState("");
    const [recentCovidInteractionPositiveNumber, setrecentCovidInteractionPositiveNumber] = useState(0);
    const [age, setAge] = useState("Under 18");
    const [gender, setGender] = useState("");
    const [genderValue, setGenderValue] = useState("");
    const [location, setLocation] = useState(null);
    const [county, setCounty] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [error, setError] = useState("");




    const handleDailySubmit = async (location, county) => {
        try {
            const payload = preparePayloadDaily(location, county);
            if (payload.county) {
                const location = await geocodePromise({ 'address': `${payload.county}, USA` });
                payload.county = location[0].formatted_address
                payload.location = {
                    type: 'Point',
                    coordinates: [location[0].geometry.location.lng(), location[0].geometry.location.lat()]
                };
            }
            else {
                payload.location = {
                    type: 'Point',
                    coordinates: [location.longitude, location.latitude]
                };
            }
            payload.user = localStorage.getItem('user')
            await http.post("/survey-response/daily", payload);
            localStorage.setItem('lastTimeSubmittedDate', new Date());
            props.handleClose();
            resetState();
        } catch (error) {
            if(error === 'ZERO_RESULTS'){
                setError("Please enter a valid US ZIP code");
                setSnackbarOpen(true);
                return
            }
            alert("Something went wrong while submitting the response.");
            props.handleClose();
        }
    };

    const preparePayloadDaily = (location, county) => {
        const payload = {};
        payload.symptoms = [];
        Object.keys(symptoms).forEach(key => {
            if (symptoms[key]) {
                payload.symptoms.push(symptomsMap[key])
            }
        });
        if (Object.keys(symptoms).some(key => symptoms[key])) {
            payload.symptomStartDate = symptomStartDate;
        }

        payload.tested = tested;
        if (payload.tested === "Yes") {
            payload.testResults = testResults;
            payload.testDate = testDate;
        }
        payload.householdCase = householdCase === "Yes" ? true : false;
        payload.numberOfhouseholdPeople = parseInt(numberOfhouseholdPeople);
        payload.recentCovidInteraction = recentCovidInteraction === "Yes" ? true : false;
        payload.recentCovidInteractionPositive = recentCovidInteractionPositive === "Yes" ? true : false;
        payload.recentCovidInteractionPositiveNumber = recentCovidInteractionPositiveNumber === parseInt(recentCovidInteractionPositiveNumber);
        if (location) {
            payload.location = { type: "Point", coordinates: [location.longitude, location.latitude] }
        }
        else {
            // payload.county = `${county['Display Text']}`
            payload.county = county;
        }
        payload.deviceType = "web";
        return payload;

    };

    const preparePayload = (protectionLevel) => {
        const payload = {};
        payload.symptoms = [];
        Object.keys(symptoms).forEach(key => {
            if (symptoms[key]) {
                payload.symptoms.push(symptomsMap[key])
            }
        });
        if (Object.keys(symptoms).some(key => symptoms[key])) {
            payload.symptomStartDate = symptomStartDate;
        }
        payload.underlyingConditions = [];
        Object.keys(underlyingConditions).forEach(key => {
            if (underlyingConditions[key])
                payload.underlyingConditions.push(underlyingConditionsMap[key])
        });
        payload.tested = tested;
        if (payload.tested === "Yes") {
            payload.testResults = testResults;
            payload.testDate = testDate;
        }
        payload.householdCase = householdCase === "Yes" ? true : false;
        payload.numberOfhouseholdPeople = parseInt(numberOfhouseholdPeople);
        payload.recentCovidInteraction = recentCovidInteraction === "Yes" ? true : false;
        payload.recentCovidInteractionPositive = recentCovidInteractionPositive === "Yes" ? true : false;
        payload.recentCovidInteractionPositiveNumber = recentCovidInteractionPositiveNumber === parseInt(recentCovidInteractionPositiveNumber);
        payload.age = age;
        if (gender === "Prefer to self describe as") {
            payload.gender = genderValue
        } else {
            payload.gender = gender;
        }
        if (location) {
            payload.location = { type: "Point", coordinates: [location.longitude, location.latitude] }
        } else {
            // payload.county = `${county['Display Text']}`
            payload.county = county;
        }
        payload.privacyLevel = protectionLevel;
        payload.deviceType = "web";
        return payload;

    };
    const handleSymptomsNext = (symptoms, symptomsStartDate) => {
        setSymptoms(symptoms);
        setSymptomStartDate(symptomsStartDate);
        setStep(1);
    };

    const handleTestedNext = (tested, testResults, testDate) => {
        setTested(tested);
        setTestResults(testResults);
        setTestDate(testDate);
        if (localStorage.getItem('lastTimeSubmittedDate')) {
            setStep(3);
        } else {
            setStep(2);
        }
    };

    const handleUnderlyingNext = (underlyingConditions) => {
        setUnderlyingConditions(underlyingConditions);
        setStep(3);
    };

    const handleHouseholdNext = (householdCase, numberOfHouseholdPeople) => {
        setHouseholdCase(householdCase);
        setHouseholdPeople(numberOfHouseholdPeople);
        setStep(4);
    };

    const handleContactNext = (recentCovidInteraction, recentCovidInteractionPositive, recentCovidInteractionPositiveNumber) => {
        setRecentCovidInteraction(recentCovidInteraction);
        setRecentCovidInteractionPositive(recentCovidInteractionPositive);
        setrecentCovidInteractionPositiveNumber(recentCovidInteractionPositiveNumber);
        if (localStorage.getItem('lastTimeSubmittedDate')) {
            setStep(6);
        } else {
            setStep(5);
        }
    };

    const handleAboutYouNext = (age, gender, genderValue) => {
        setAge(age);
        setGender(gender);
        setGenderValue(genderValue);
        setStep(6);
    };

    const handleLocationNext = (location) => {
        setLocation(location);
        setStep(8);
    };

    const handleDailyNext = (location) => {
        setLocation(location);
        handleDailySubmit(location);
    };

    const handleLocationSkip = () => {
        setLocation(null);
        setStep(7);
    };

    const handleCountyNext = (county) => {
        if (localStorage.getItem('lastTimeSubmittedDate')) {
            setCounty(county);
            handleDailySubmit(null, county);
        }
        else {
            setCounty(county);
            setStep(8);
        }
    };

    const handleProtectionBack = () => {
        if (location) {
            setStep(6);
        } else {
            setStep(7);
        }
    };

    const geocodePromise = (params) => {
        const geocoder = window.geoCoder;
        return new Promise(function (resolve, reject) {
            geocoder.geocode(params, function (results, status) {
                if (status !== "OK") {
                    reject(status)
                } else {
                    resolve(results)
                }
            })
        })
    };

    const resetState = () => {
        setStep(0);
        setSymptoms({
            fever: false,
            cough: false,
            soarThroat: false,
            runnyNose: false,
            shortnessOfBreath: false,
            headache: false,
            diarrhea: false,
            lossOfSmell: false,
            lossOfTaste: false,
            nauseaOrVomiting: false,
            musclePain: false,
            chills: false,
            abdominalPain: false,
            noSymptoms: false
        });
        setSymptomStartDate("");
        setTested("");
        setTestResults("");
        setTestDate("");
        setUnderlyingConditions({
            asthma: false,
            lungDisease: false,
            hyperTension: false,
            diabetes: false,
            heartDisease: false,
            obesity: false,
            immunocomprosied: false,
        });
        setHouseholdCase("");
        setHouseholdPeople("");
        setRecentCovidInteractionPositive("");
        setRecentCovidInteraction("");
        setAge("Under 18");
        setGender("");
        setGenderValue("");
        setLocation("");
        setCounty(null);
    };


    const handleFirstTimeSubmit = async (protectionLevel) => {
        try {
            const payload = preparePayload(protectionLevel);
            if (payload.county) {
                const location = await geocodePromise({ 'address': payload.county });
                payload.county = location[0].formatted_address;
                payload.location = {
                    type: 'Point',
                    coordinates: [location[0].geometry.location.lng(), location[0].geometry.location.lat()]
                };
            }
            else {
                payload.location = {
                    type: 'Point',
                    coordinates: [location.longitude, location.latitude]
                };
            }
            payload.deviceId = localStorage.getItem('deviceId');
            payload.user = localStorage.getItem('user');
            // payload.deviceId = uuidv1();
            await http.post("/survey-response/first-time", payload);
            localStorage.setItem('lastTimeSubmittedDate', new Date());
            props.handleClose();
            resetState();
        } catch (error) {
            if(error === 'ZERO_RESULTS'){
                setError("Please enter a valid US ZIP code");
                setSnackbarOpen(true);
                return
            }
            alert("Something went wrong while submitting the response.");
            props.handleClose();
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setError("");
        setSnackbarOpen(false);
    };

    return (
        <Dialog className={classes.root} open={props.open} fullScreen={true} style={{ overflowY: 'auto' }}>
            <AppBar position={"relative"}>
                <Toolbar>
                    <Typography variant="h5">
                        Share how you feel
                    </Typography>
                    <IconButton style={{ marginLeft: "auto" }} onClick={() => {
                        resetState();
                        props.handleClose();
                    }} color="inherit" aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent
                style={{ flexGrow: 1, padding: theme.spacing(10, 10, 0, 10), display: "flex", justifyContent: "center", minHeight: 'calc(100vh - 64px)' }}>
                {step === 0 &&
                    <Survey handleNext={handleSymptomsNext} symptoms={symptoms} symptomStartDate={symptomStartDate} />}
                {step === 1 &&
                    <SurveyTested handleNext={handleTestedNext} tested={tested} testResults={testResults}
                        testDate={testDate}
                        setStep={setStep} />}
                {step === 2 &&
                    <SurveyUnderlying handleNext={handleUnderlyingNext} underlyingConditions={underlyingConditions}
                        setStep={setStep} />}
                {step === 3 && <SurveyHousehold handleNext={handleHouseholdNext} householdCase={householdCase}
                    numberOfhouseholdPeople={numberOfhouseholdPeople} setStep={setStep} />}
                {step === 4 &&
                    <SurveyContact handleNext={handleContactNext} recentCovidInteraction={recentCovidInteraction}
                        recentCovidInteractionPositive={recentCovidInteractionPositive} setStep={setStep} />}
                {step === 5 &&
                    <SurveyAboutYou handleNext={handleAboutYouNext} gender={gender} age={age} genderValue={genderValue}
                        setStep={setStep} />}
                {step === 6 &&
                    <SurveyLocation handleNext={handleLocationNext} location={location} handleSkip={handleLocationSkip}
                        setStep={setStep} handleDailyNext={handleDailyNext} />}
                {step === 7 &&
                    <SurveyCounty handleNext={handleCountyNext} county={county} setStep={setStep} />}
                {step === 8 &&
                    <SurveyProtection handleSubmit={handleFirstTimeSubmit} handleBack={handleProtectionBack} />}
            </DialogContent>
            <Snackbar open={snackbarOpen} onClose={handleClose} autoHideDuration={3000}>
                <Alert severity={"error"}>{error}</Alert>
            </Snackbar>
        </Dialog>
    )
}