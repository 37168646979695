import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Map from "./Maps";
import Button from "@material-ui/core/Button";
import { useMediaQuery } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Divider from "@material-ui/core/Divider";
import { isMobile } from "react-device-detect";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import pageIds from '../utils/enums/PageId'
import { http } from "../utils/http";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    boxShadow: "2px 0px 4px rgba(0,0,0,0.2)",
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));

export default function PermanentDrawerLeft(props) {
  const classes = useStyles();
  const mobile = useMediaQuery("(max-width:600px)");
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [open, setOpen] = useState(
    JSON.parse(localStorage.getItem("onboardingOpen")) === false ? false : true
  );
  const displayDialogue = () => {
    if (!localStorage.getItem("lastTimeSubmittedDate")) {
      return true;
    }
    let lastSubmitted = new Date(localStorage.getItem("lastTimeSubmittedDate"));
    lastSubmitted.setTime(
      lastSubmitted.getTime() + lastSubmitted.getTimezoneOffset() * 60 * 1000
    );

    let offset = -300; //Timezone offset for EST in minutes.
    let estDate = new Date(lastSubmitted.getTime() + offset * 60 * 1000);

    let today = new Date();
    today.setTime(today.getTime() + today.getTimezoneOffset() * 60 * 1000);
    let todayEstDate = new Date(today.getTime() + offset * 60 * 1000);
    if (lastSubmitted) {
      if (estDate.getDate() < todayEstDate.getDate()) {
        return true;
      }
    } else {
      return true;
    }
    return false;
  };

  const handleSubmit = async () => {
    const data = {
      eventType: "activity",
      fromPage: pageIds.dashboad,
      page: pageIds.privacyPolicy,
      actionOnPage: "next",
      deviceId: localStorage.getItem("deviceId"),
      time: new Date(),
      user: localStorage.getItem("user")
    };
    await http.post("/events", data);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <IconButton
        style={{
          backgroundColor: "white",
          position: "fixed",
          top: 10,
          left: 10,
          padding: 5,
          zIndex: 200,
          display: mobile ? "block" : "none",
        }}
        onClick={() => setDrawerOpen(true)}
      >
        <MenuIcon />
      </IconButton>

      <Drawer
        className={classes.drawer}
        variant={mobile ? "persistent" : "permanent"}
        open={drawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        {mobile && (
          <>
            <div style={{ marginLeft: "auto", padding: theme.spacing(0, 1) }}>
              <IconButton onClick={() => setDrawerOpen(false)}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider style={{ marginBottom: theme.spacing(2) }} />
          </>
        )}
        {!mobile && <div className={classes.toolbar} />}
        <img
          src={require("../media/logo-covid-nearby-website.png")}
          style={{
            cursor: "pointer",
            width: "80%",
            marginLeft: 20,
            marginRight: 20,
            marginBottom: 20,
          }}
          onClick={() => window.open("https://covidnearby.org", "_self")}
          alt="logo"
        />
        <List>
          <ListItem button style={{ paddingTop: 4, paddingBottom: 4 }}>
            <ListItemText
              primary={"Home"}
              onClick={() => window.open("https://covidnearby.org", "_self")}
            />
          </ListItem>
          <ListItem button style={{ paddingTop: 4, paddingBottom: 4 }}>
            <ListItemText
              primary={"Contact"}
              onClick={() =>
                window.open("mailto:info@covidnearby.org", "_self")
              }
            />
          </ListItem>
          <ListItem button style={{ paddingTop: 4, paddingBottom: 4 }}>
            <ListItemText
              primary={"FAQs"}
              onClick={() =>
                window.open("https://covidnearby.org/faqs/", "_self")
              }
            />
          </ListItem>
          <ListItem button style={{ paddingTop: 4, paddingBottom: 4 }}>
            <ListItemText
              primary={"Privacy Matters"}
              onClick={() =>
                window.open(
                  "https://covidnearby.org/differential-privacy-methods/",
                  "_self"
                )
              }
            />
          </ListItem>
          <ListItem button style={{ paddingTop: 4, paddingBottom: 4 }}>
            <ListItemText
              primary={"Privacy Policy"}
              onClick={() => {
                window.open("https://covidnearby.org/privacy-policy/", "_self");
                handleSubmit();
              }}
            />
          </ListItem>
          <ListItem button style={{ paddingTop: 4, paddingBottom: 4 }}>
            <ListItemText
              primary={"Terms of Use"}
              onClick={() =>
                window.open("https://covidnearby.org/terms/", "_self")
              }
            />
          </ListItem>
          <ListItem button style={{ paddingTop: 4, paddingBottom: 4 }}>
            <ListItemText
              primary={"Sponsors "}
              onClick={() =>
                window.open("https://covidnearby.org/sponsors/", "_self")
              }
            />
          </ListItem>
        </List>
        <img
          src={require("../media/Rutgers_Logo.png")}
          style={{
            width: "54%",
            marginLeft: "23%",
            marginRight: "23%",
            marginBottom: "15%",
            marginTop: "auto",
          }}
          alt={"Rutgers logo"}
        />
        <p
          style={{
            textAlign: "center",
            textDecoration: "underline",
            fontWeight: "bold",
            fontSize: 16,
          }}
        >
          SUPPORTED BY
        </p>
        <img
          src={require("../media/support-300x139.png")}
          style={{
            width: "54%",
            marginLeft: "23%",
            marginRight: "23%",
            marginBottom: "10%",
          }}
          alt={"support"}
        />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Map isMobile={isMobile} />
        {displayDialogue() ? (
          <>
            {isMobile ? (
              <div
                style={{
                  position: "absolute",
                  top: "100%",
                  marginTop: -79,
                  marginLeft: -112,
                  left: "50%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <Button
                      variant={"contained"}
                      color={"primary"}
                      onClick={() => props.handleButton()}
                    >
                      SHARE HOW YOU FEEL
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  position: "absolute",
                  bottom: 24,
                  left: 260,
                  backgroundColor: "white",
                  padding: "50px 30px",
                  borderRadius: 8,
                  boxShadow:
                    "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <p style={{ textAlign: "center" }}>Help us track COVID-19</p>
                  <div style={{ marginTop: 20 }}>
                    <Button
                      variant={"contained"}
                      color={"primary"}
                      onClick={() => props.handleButton()}
                    >
                      SHARE HOW YOU FEEL
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {isMobile ? null : (
              <div
                style={{
                  position: "absolute",
                  bottom: 24,
                  left: 260,
                  backgroundColor: "white",
                  padding: "60px 30px",
                  borderRadius: 8,
                  boxShadow:
                    "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <p style={{ textAlign: "center" }}>
                    We have your updated information. <br />
                    Please submit an update tomorrow.
                  </p>
                </div>
              </div>
            )}
          </>
        )}
      </main>
      <Dialog
        open={open && props.onboardingOpen}
        onClose={() => setOpen(false)}
        disableBackdropClick={true}
      >
        <DialogContent>
          <DialogContentText>
            1. Move the map & search to see confirmed COVID-19 cases
            <br />
            2. Click on any circle to see further details
            <br />
            3. Toggle to see reported symptoms
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              localStorage.setItem("onboardingOpen", JSON.stringify(false));
              setOpen(false);
              props.setOnboardingOpen(false);
            }}
            color="primary"
          >
            Don't show again
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
              props.setOnboardingOpen(false);
            }}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
