import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { useTheme } from "@material-ui/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "./Alert";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider,DatePicker } from '@material-ui/pickers';

export default function Survey(props) {
    const [symptoms, setSymptoms] = useState({
        noSymptoms: false,
        fever: false,
        cough: false,
        soarThroat: false,
        runnyNose: false,
        shortnessOfBreath: false,
        headache: false,
        diarrhea: false,
        lossOfSmell: false,
        lossOfTaste: false,
        nauseaOrVomiting: false,
        musclePain: false,
        chills: false,
        abdominalPain: false
    });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [symptomStartDate, setSymptomStartDate] = useState("");
    const [error, setError] = useState("");
    useEffect(() => {
        setSymptoms(props.symptoms);
        setSymptomStartDate(props.symptomStartDate);
    }, [props.symptoms, props.symptomStartDate]);
    const { noSymptoms, fever, cough, soarThroat, runnyNose, shortnessOfBreath, headache, diarrhea, lossOfSmell, lossOfTaste, nauseaOrVomiting, musclePain, chills, abdominalPain } = symptoms;
    const handleChange = (event) => {
        if (event.target.name === 'noSymptoms') {
            if(event.target.checked) {
                setSymptoms({
                  fever: false,
                  cough: false,
                  soarThroat: false,
                  runnyNose: false,
                  shortnessOfBreath: false,
                  headache: false,
                  diarrhea: false,
                  lossOfSmell: false,
                  lossOfTaste: false,
                  nauseaOrVomiting: false,
                  musclePain: false,
                  chills: false,
                  abdominalPain: false,
                  noSymptoms: true
                })
            } else {
                setSymptoms({[event.target.name]: event.target.checked})
            }
        }
        else {
            if (!symptoms.noSymptoms) {
                setSymptoms({ ...symptoms, [event.target.name]: event.target.checked })
            }
        }
    };
    const theme = useTheme();

  const handleNext = () => {
    if(Object.keys(symptoms).every(val => !symptoms[val])) {
      setError("Please select no symptoms if you have none");
      setSnackbarOpen(true);
      return;
    }
    if (Object.keys(symptoms).some(val => symptoms[val]) && symptomStartDate === "" && !symptoms.noSymptoms) {
      setError("Symptom start date is required");
      setSnackbarOpen(true);
    } else {
      props.handleNext(symptoms, symptomStartDate);
    }
  };

  const getButtonVariant = ()=> {
    if(symptoms["noSymptoms"]) {
      return "contained"
    }
    if (Object.keys(symptoms).some(val => symptoms[val]) && symptomStartDate !== "") {
      return "contained"
    }
    return "outlined"
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError("");
    setSnackbarOpen(false);
  };
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant={"h5"}>
                Do you currently have any of these COVID-19 symptoms?
            </Typography>
            <Typography variant={"subtitle2"}>
                Select all that apply
            </Typography>
            <FormGroup>
                <FormControlLabel
                    control={<Checkbox checked={noSymptoms} onChange={handleChange} name="noSymptoms" />}
                    label="No symptoms"
                />
                <FormControlLabel
                    control={<Checkbox checked={fever} onChange={handleChange} name="fever" />}
                    label="Fever"
                />
                <FormControlLabel
                    control={<Checkbox checked={cough} onChange={handleChange} name="cough" />}
                    label="Cough (new or worsening)"
                />
                <FormControlLabel
                    control={<Checkbox checked={soarThroat} onChange={handleChange} name="soarThroat" />}
                    label="Sore Throat"
                />
                <FormControlLabel
                    control={<Checkbox checked={runnyNose} onChange={handleChange} name="runnyNose" />}
                    label="Runny nose"
                />
                <FormControlLabel
                    control={<Checkbox checked={shortnessOfBreath} onChange={handleChange} name="shortnessOfBreath" />}
                    label="Shortness of breath or trouble breathing (new or worsening)"
                />
                <FormControlLabel
                    control={<Checkbox checked={headache} onChange={handleChange} name="headache" />}
                    label="Headache"
                />
                <FormControlLabel
                    control={<Checkbox checked={diarrhea} onChange={handleChange} name="diarrhea" />}
                    label="Diarrhea"
                />
                <FormControlLabel
                    control={<Checkbox checked={lossOfSmell} onChange={handleChange} name="lossOfSmell" />}
                    label="Loss of sense of smell"
                />
                <FormControlLabel
                    control={<Checkbox checked={lossOfTaste} onChange={handleChange} name="lossOfTaste" />}
                    label="Loss of sense of taste"
                />
                <FormControlLabel
                    control={<Checkbox checked={nauseaOrVomiting} onChange={handleChange} name="nauseaOrVomiting" />}
                    label="Nausea/Vomiting"
                />
                <FormControlLabel
                    control={<Checkbox checked={musclePain} onChange={handleChange} name="musclePain" />}
                    label="Muscle Aches"
                />
                <FormControlLabel
                    control={<Checkbox checked={chills} onChange={handleChange} name="chills" />}
                    label="Chills"
                />
                <FormControlLabel
                    control={<Checkbox checked={abdominalPain} onChange={handleChange} name="abdominalPain" />}
                    label="Abdominal Pain"
                />
                {Object.keys(symptoms).some(val => symptoms[val]) && !symptoms.noSymptoms && <div>
                    <Typography variant={"h5"}>
                        Approximately When did the symptoms start?
                    </Typography>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            autoOk
                            maxDate={new Date()}
                            value={symptomStartDate?symptomStartDate:null}
                            label={"Date"}
                            format="MM/dd/yyyy"
                            variant="inline"
                            inputVariant="outlined"
                            margin={"normal"}
                            style={{ minWidth: 200 }}
                            InputLabelProps={{ shrink: true }}
                            onChange={(date) => { setSymptomStartDate(date) }}
                        />
                    </MuiPickersUtilsProvider>
                </div>}
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: theme.spacing(1), marginBottom: theme.spacing(10) }}>
                    <Button variant={getButtonVariant()} color={"primary"} onClick={handleNext}>
                        Next
                    </Button>
                </div>
                <Snackbar open={snackbarOpen} onClose={handleClose} autoHideDuration={3000}>
                    <Alert severity={"error"}>{error}</Alert>
                </Snackbar>
            </FormGroup>
        </div>
    )
}