module.exports = {
    logoSplashScreen: "Logo Splash Screen",
    sponsorSplashScreen: "Sponsors Splash Screen",
    covidNearby: "At COVID Nearby",
    donateData: "Donate Data",
    symptoms: "Symptoms",
    symptomsDate: "Symptoms Date",
    tested: "Tested",
    underlyingConditions: "Underlying Conditions",
    household: "Household",
    contactWithAffected: "Contact With Affected",
    ageAndSex: "Age & Sex",
    dailyUpdates: "Daily Updates",
    location: "Location",
    zipCode: "Zip Code",
    privacy: "Privacy",
    almostDone: "Almost Done",
    formSavedSuccessfully: "Form Saved Successfully",
    dashboad: "Dashboard",
    dashboardSymptoms: "Dashboard Symptoms",
    dashboardDeathsOrCases: "Dashboard Deaths/Cases",
    countyDetail: "County Detail",
    menu: "Menu",
    about: "About",
    faqs: "FAQs",
    privacyPolicy: "Privacy Policy",
    privacyMethods: "Privacy Methods",
    termsAndConditions: "Terms & Conditions",
    dailyCheckIn: "Daily Check-In Intro",

};