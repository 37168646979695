import React, { useState } from "react";
import "./App.css";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import { v1 as uuidv1 } from "uuid";
import { http } from "./utils/http";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0033",
    },
    secondary: {
      main: "#5f6a72",
    },
  },
});

function App() {
  const [onboardingOpen, setOnboardingOpen] = useState(true);
  if (!localStorage.getItem("deviceId")) {
    let deviceId = uuidv1();
    localStorage.setItem("deviceId", uuidv1());
    const data = {
      deviceId: deviceId,
      deviceType: "web",
    };
    http.post("/users", data).then((resp) => {
      localStorage.setItem("user", resp.data.data._id);
      localStorage.setItem("deviceId", resp.data.data.deviceId);
    });
  }
  return (
    // eslint-disable-next-line react/jsx-no-undef
    <ThemeProvider theme={{ ...theme, colorGray: "#5f6a72" }}>
      <Router>
        <Switch>
          <Route
            path={"/"}
            render={(props) => (
              <Home
                onboardingOpen={onboardingOpen}
                setOnboardingOpen={setOnboardingOpen}
                {...props}
              />
            )}
          />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
