import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SurveyForm from "../components/SurveyForm";
import Dashboard from "../components/Dashboard";
import CookieConsent from "react-cookie-consent";

const useStyles = makeStyles(theme => ({
    container: {
        paddingLeft: theme.spacing(10),
        paddingRight: theme.spacing(10),
        paddingTop: theme.spacing(10),
    }
}));
export default function Home(props) {
    let lastSubmitted = localStorage.getItem('lastTimeSubmittedDate') ? false : true
    const classes = useStyles();
    const [open, setOpen] = useState(lastSubmitted);
    return (
        <>
            <div className={classes.container}>
                {
                    open
                        ?
                        <SurveyForm open={open} handleClose={() => setOpen(false)} />
                        :
                        <Dashboard onboardingOpen={props.onboardingOpen} setOnboardingOpen={props.setOnboardingOpen} handleButton={() => setOpen(true)} />
                }
            </div>
            <CookieConsent
                location="bottom"
                buttonText="Accept"
                cookieName="covidNearbyCookie"
                style={{ background: "#2B373B",zIndex:1500 }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={10000}
            >
                This website uses cookies to improve and to understand user behavior. 
                By continuing to use this website, you are consenting to the <span onClick={() => { window.open("https://covidnearby.org/privacy-policy/", "_self")}} style={{ color:'rgb(255, 212, 45)', cursor:'pointer', fontWeight:'bold', textDecoration:'underline' }}>Privacy Policy</span> and 
                placement and retrieval of cookies on your computer by this website.
            </CookieConsent>
      </>
    );
}