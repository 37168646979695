import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import useTheme from "@material-ui/core/styles/useTheme";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import Alert from "./Alert";
import pageIds from "../utils/enums/PageId";
import {http} from "../utils/http";

export default function SurveyCounty(props) {
    const [county, setCounty] = useState(null);
    const [error, setError] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    useEffect(() => {
        setCounty(props.county);
    }, [props.county]);
    const theme = useTheme();

    const handleNext = () => {
        if (!county) {
            setError("Please enter a zip code");
            setSnackbarOpen(true);
            return;
        }
        else {
            if (parseInt(county) < 0) {
                setError("Please enter a valid US zip code");
                setSnackbarOpen(true);
                return;
            }
            if (county.length === 5) {
                // handleEventSubmission();
                props.handleNext(county);
            }
            else if(county.length > 5){
                setError("Please enter a valid US zip code");
                setSnackbarOpen(true);
                return;
            }
            else {
                setError("Please enter a valid US zip code");
                setSnackbarOpen(true);
                return;
            }
        }
    };

    const handleEventSubmission = async()=> {
        if (localStorage.getItem('user')) {
            const data = {
                eventType: "activity",
                page: pageIds.location,
                actionOnPage: "submit",
                user: localStorage.getItem('user')
            }
            await http.post('/events', data);
        } else {
            const data = {
                eventType: "activity",
                fromPage: pageIds.location,
                page: pageIds.privacyPolicy,
                actionOnPage: "next",
                deviceId : localStorage.getItem('deviceId')
            };
            await http.post('/events', data);
        }
    }

    const getButtonVariant = () => {
        if (!county) {
            return "outlined";
        }
        return "contained";
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setError("");
        setSnackbarOpen(false);
    };

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant={"h4"} style={{ color: theme.colorGray, fontWeight: 500 }}> LOCATION</Typography>
            <Typography variant={"body1"} style={{ marginTop: theme.spacing(1) }}>Please enter your zip code
                instead</Typography>
            <div style={{ marginTop: theme.spacing(5) }}>
                <Typography variant={"h5"}>Zip code</Typography>
                <TextField
                    type={"number"}
                    variant="outlined"
                    margin="normal"
                    value={county}
                    onChange={(e, v) => {
                        setCounty(e.target.value)
                    }}
                    style={{ minWidth: 200 }}
                />
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: theme.spacing(3) }}>
                <Button variant={"outlined"} color={"primary"} onClick={() => localStorage.getItem('user') ? props.setStep(4) : props.setStep(5)}>
                    Back
                </Button>
                <Button variant={getButtonVariant()} color={"primary"} style={{ marginLeft: theme.spacing(3) }}
                    onClick={() => handleNext()}>
                    {
                        localStorage.getItem("lastTimeSubmittedDate")
                            ?
                            "Submit"
                            :
                            "Next"
                    }
                </Button>
            </div>
            <Snackbar open={snackbarOpen} onClose={handleClose} autoHideDuration={3000}>
                <Alert severity={"error"}>{error}</Alert>
            </Snackbar>
        </div>
    )
}