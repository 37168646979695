import React, {useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Button from "@material-ui/core/Button";
import {useTheme} from "@material-ui/styles";


export default function SurveyUnderlying(props) {
    const [underlyingConditions, setUnderlyingConditions] = useState({
        asthma: false,
        lungDisease: false,
        hyperTension: false,
        diabetes: false,
        heartDisease: false,
        obesity: false,
        immunocomprosied: false,
    });
    useEffect(() => {
        setUnderlyingConditions(props.underlyingConditions);
    }, [props.underlyingConditions]);
    const theme = useTheme();

    const handleChange = (event) => setUnderlyingConditions({
        ...underlyingConditions,
        [event.target.name]: event.target.checked
    });
    const handleNext = () => {
        props.handleNext(underlyingConditions);
    };
    const {asthma, lungDisease, hyperTension, diabetes, heartDisease, obesity, immunocomprosied} = underlyingConditions;
    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <Typography variant={"h5"}>
                Do you have any of the following conditions?
            </Typography>
            <Typography variant={"subtitle2"}>
                Select all that apply
            </Typography>
            <FormGroup>
                <FormControlLabel
                    control={<Checkbox checked={asthma} onChange={handleChange} name="asthma"/>}
                    label="Asthma"
                />
                <FormControlLabel
                    control={<Checkbox checked={lungDisease} onChange={handleChange} name="lungDisease"/>}
                    label="Lung disease"
                />
                <FormControlLabel
                    control={<Checkbox checked={hyperTension} onChange={handleChange} name="hyperTension"/>}
                    label="Hypertension"
                />
                <FormControlLabel
                    control={<Checkbox checked={diabetes} onChange={handleChange} name="diabetes"/>}
                    label="Diabetes"
                />
                <FormControlLabel
                    control={<Checkbox checked={heartDisease} onChange={handleChange} name="heartDisease"/>}
                    label="Heart Disease"
                />
                <FormControlLabel
                    control={<Checkbox checked={obesity} onChange={handleChange} name="obesity"/>}
                    label="Obesity i.e. body-mass-index >30"
                />
                <FormControlLabel
                    control={<Checkbox checked={immunocomprosied} onChange={handleChange} name="immunocomprosied"/>}
                    label="Immunocompromised (e.g., due to HIV or chemotherapy)"
                />
            </FormGroup>
            <div style={{display: "flex", justifyContent: "flex-end", marginTop: theme.spacing(1)}}>
                <Button variant={"outlined"} color={"primary"} onClick={() => props.setStep(1)}>
                    Back
                </Button>
                <Button variant={"contained"} color={"primary"} style={{marginLeft: theme.spacing(2)}}
                        onClick={handleNext}>
                    Next
                </Button>
            </div>
        </div>
    )
}