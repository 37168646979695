import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import { useMediaQuery, useTheme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { http } from "../utils/http";
import pageIds from '../utils/enums/PageId'

const sliderOptions = [{ value: 1, text: 1 }, { value: 2, text: 2 }, { value: 3, text: 3 }, { value: 4, text: 4 }, {
    value: 5,
    text: 5
}];
export default function SurveyProtection(props) {
    const [protectionLevel, setProtectionLevel] = useState(3);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const theme = useTheme();
    const mobile = useMediaQuery('(max-width:600px)')

    const handleSubmit = async () => {
        const data = {
            eventType: "activity",
            fromPage: pageIds.privacy,
            page: pageIds.privacyPolicy,
            actionOnPage: "next",
            deviceId : localStorage.getItem('deviceId'),
            time: new Date(),
            user: localStorage.getItem("user")
        };
        await http.post('/events', data)
    };
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant={"h5"}>
                Select a protection level
            </Typography>
            <Typography variant={"body1"} style={{ marginTop: theme.spacing(1) }}>We provide a default privacy guarantee for
                all. Click <span style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={() => { window.open("https://covidnearby.org/privacy-qdocs/", "_target"); handleSubmit(); }}>
                    here
            </span> to learn more</Typography>
            <Typography variant={"body1"} style={{ marginTop: theme.spacing(1) }}>You can also change your privacy
                settings by moving a slider below.</Typography>
            <div style={{ display: "flex", justifyContent: "center", flexDirection: 'column', alignItems: 'center' }}>
                <div style={{
                    marginTop: theme.spacing(5),
                    borderRadius: 30
                }}>
                    <Slider
                        color={"primary"}
                        style={{ width: mobile ? 300 : 400, padding: 0 }}
                        value={protectionLevel}
                        onChange={(e, v) => setProtectionLevel(v)}
                        valueLabelFormat={(value) => sliderOptions.findIndex((mark) => mark.value === value) + 1}
                        step={null}
                        marks={sliderOptions}
                        min={1}
                        max={5}
                    />
                </div>
                <div style={{ width: mobile ? 300 : 400, color: 'black', marginTop: theme.spacing(2), display: "flex", justifyContent: "space-between" }}>
                    <div style={{ width: '30%' }}>
                        <div style={{ height: 75, width: 0, border: '1px solid' }}>

                        </div>
                        <span>Lower privacy, more useful data</span>
                    </div>
                    <div style={{ width: '30%', display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <div style={{ height: 37, width: 0, border: '1px solid' }}>

                        </div>
                        <span>Default Privacy</span>
                    </div>
                    <div style={{ width: '30%', display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                        <div style={{ height: 75, width: 0, border: '1px solid' }}>

                        </div>
                        <span style={{ textAlign: "right" }}>Higher privacy, less useful data</span>
                    </div>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: theme.spacing(3) }}>
                <Button variant={"outlined"} color={"primary"} onClick={() => props.handleBack()} disabled={isSubmitted}>
                    Back
                </Button>
                <Button variant={"contained"} style={{ marginLeft: theme.spacing(3) }} color={"primary"} disabled={isSubmitted}
                    onClick={() => {
                        setIsSubmitted(true);
                        props.handleSubmit(protectionLevel)
                    }}>
                    Submit
                </Button>
            </div>
        </div>
    )
}