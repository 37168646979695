import React, {useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";
import {useTheme} from "@material-ui/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import Alert from "./Alert";

export default function SurveyHousehold(props) {
    const [householdCase, setHouseholdCase] = useState("");
    const [numberOfhouseholdPeople, setHouseholdPeople] = useState("");
    const [error, setError] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    useEffect(()=>{
        setHouseholdCase(props.householdCase);
        setHouseholdPeople(props.numberOfhouseholdPeople);
    }, [props.householdCase, props.numberOfhouseholdPeople]);

    const theme = useTheme();

    const handleNext = () => {
        if (householdCase === "") {
            setError("Household case information is required");
            setSnackbarOpen(true);
            return;
        }
        if (!numberOfhouseholdPeople) {
            setError("Number of household people is required");
            setSnackbarOpen(true);
            return;
        }
        let number = Number(numberOfhouseholdPeople);
        if ((number % 1) !== 0) {
            setError("Number of household people must not be a decimal number");
            setSnackbarOpen(true);
            return;
        }
        if (parseInt(numberOfhouseholdPeople) <= 0) {
            setError("Number of household people must be greater than 0");
            setSnackbarOpen(true);
            return;
        }
        props.handleNext(householdCase, numberOfhouseholdPeople);
    };

    const getButtonVariant = ()=> {
        if (householdCase === "") {
            return "outlined";
        }
        if (!numberOfhouseholdPeople) {
            return "outlined";
        }
        let number = Number(numberOfhouseholdPeople);
        if ((number % 1) !== 0) {
            return "outlined";
        }
        if (parseInt(numberOfhouseholdPeople) <= 0) {
            return "outlined";
        }
        return "contained"
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setError("");
        setSnackbarOpen(false);
    };

    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <Typography variant={"h5"}>
                Does anyone in your household currently have any of the COVID-19 symptoms?
            </Typography>
            <RadioGroup aria-label="householdCase" name="householdCase1" value={householdCase}
                        onChange={(e) =>setHouseholdCase(e.target.value)}>
                <FormControlLabel value="Yes" control={<Radio/>} label="Yes"/>
                <FormControlLabel value="No" control={<Radio/>} label="No"/>
            </RadioGroup>
            <div>
                <Typography variant={"h5"} style={{marginTop: theme.spacing(2)}}>
                    How many people live in your household?
                </Typography>
                <TextField
                    type={"number"}
                    variant="outlined"
                    margin="normal"
                    value={numberOfhouseholdPeople}
                    onChange={e => {
                        setHouseholdPeople(e.target.value)
                    }}
                    style={{minWidth: 200}}
                />
            </div>
            <div style={{display: "flex", justifyContent: "flex-end", marginTop: theme.spacing(1)}}>
                <Button variant={"outlined"} color={"primary"} onClick={() => props.setStep(2)}>
                    Back
                </Button>
                <Button variant={getButtonVariant()} color={"primary"} style={{marginLeft: theme.spacing(3)}}
                        onClick={handleNext}>
                    Next
                </Button>
            </div>
            <Snackbar open={snackbarOpen} onClose={handleClose} autoHideDuration={3000}>
                <Alert severity={"error"}>{error}</Alert>
            </Snackbar>
        </div>
    )
}