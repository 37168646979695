import React, { useEffect, useState } from "react";
import useTheme from "@material-ui/core/styles/useTheme";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import Alert from "./Alert";
import { http } from "../utils/http";
import pageIds from '../utils/enums/PageId'

export default function SurveyLocation(props) {
    const [error, setError] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [location, setLocation] = useState(null);
    const theme = useTheme();
    useEffect(() => setLocation(props.location), [props.location]);
    const getLocation = () => {
        if (!navigator.permissions) {
            navigator.geolocation.getCurrentPosition((data) => {
                setLocation({ latitude: data.coords.latitude, longitude: data.coords.longitude });
            }, () => {
                setError("Location access is denied. Please go to the browser settings and allow location.");
                setSnackbarOpen(true);
            })
            return;
        }
        navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
            if (result.state === 'granted') {
                navigator.geolocation.getCurrentPosition((data) => {
                    setLocation({ latitude: data.coords.latitude, longitude: data.coords.longitude })
                })
            } else if (result.state === 'prompt') {
                navigator.geolocation.getCurrentPosition((data) => {
                    setLocation({ latitude: data.coords.latitude, longitude: data.coords.longitude });
                }, () => {
                    setError("Location access is denied. Please go to the browser settings and allow location.");
                    setSnackbarOpen(true);
                })
            } else if (result.state === 'denied') {
                setError("Location access is denied. Please go to the browser settings and allow location.");
                setSnackbarOpen(true);
            }
        })
    };

    const handleNext = async () => {
        if (!location) {
            setError("Please skip if you do not wish to provide your location");
            setSnackbarOpen(true);
            return;
        }
        props.handleNext(location);
    };

    const getButtonVariant = () => {
        if (!location) {
            return "outlined";
        }
        return "contained";
    };

    const handleDaily = async () => {
        props.handleDailyNext(location);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleSubmit = async () => {
        const data = {
            eventType: "activity",
            fromPage: pageIds.location,
            page: pageIds.privacyPolicy,
            actionOnPage: "next",
            deviceId : localStorage.getItem('deviceId'),
            time: new Date(),
            user: localStorage.getItem("user")
        };
        await http.post('/events', data)
    }


    return (
        <div style={{ display: "flex", flexDirection: "column", paddingBottom: theme.spacing(10) }}>
            <Typography variant={"h4"} style={{ color: theme.colorGray }}>
                Location
            </Typography>
            <Typography variant={"body1"} style={{ marginTop: theme.spacing(1) }}>
                To monitor the virus in your area we need your location. We use your location for research only, as
                described <span style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={() => { window.open("https://covidnearby.org/privacy-qdocs/", "_target"); handleSubmit(); }}>
                    here.
            </span>
            </Typography>
            <div style={{ display: "flex", marginTop: theme.spacing(4), justifyContent: "center" }}>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    style={{
                        padding: theme.spacing(1, 5),
                        borderRadius: 30,
                        color: "white",
                        textTransform: "none"
                    }}
                    onClick={() => getLocation()}
                >
                    Get Location
                </Button>
            </div>
             <div style={{ display: "flex",justifyContent: "center"}}>
                {
                    location
                    ?
                    <p style={{ color: '#cc0033', fontWeight:500, fontSize:15 }}>location fetched successfully!</p>
                    :
                    <p style={{margin:14}}/>
                }
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: theme.spacing(10) }}>
                <Button color={"primary"} onClick={() => props.handleSkip()}>
                    Enter Location Manually
                </Button>
                <Button variant={"outlined"} color={"primary"} style={{ marginLeft: theme.spacing(3) }}
                    onClick={() => localStorage.getItem('user') ? props.setStep(4) : props.setStep(5)}>
                    Back
                </Button>
                {!localStorage.getItem("lastTimeSubmittedDate") ?
                    <Button variant={getButtonVariant()} color={"primary"} style={{ marginLeft: theme.spacing(3) }}
                        onClick={handleNext}>
                        Next
                </Button>
                    :
                    <Button disabled={!location} variant={"contained"} color={"primary"} style={{ marginLeft: theme.spacing(3) }} onClick={handleDaily}>
                        Submit
                    </Button>
                }
            </div>
            <Snackbar open={snackbarOpen} onClose={handleClose} autoHideDuration={3000}>
                <Alert severity={"error"}>{error}</Alert>
            </Snackbar>
        </div>
    )
}