import React, {Component} from "react";

import debounce from 'debounce-promise';
import {
  Map,
  InfoWindow,
  Circle,
} from "google-maps-react";
import {withRouter} from "react-router-dom";
import "../styles/map.css";
import {isMobile} from 'react-device-detect';
import {withStyles} from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

const styles = [
  {
    "featureType": "administrative.country",
    "elementType": "labels",
    "stylers": [
      {
        "lightness": 50
      }
    ]
  },
  {
    "featureType": "administrative.province",
    "elementType": "labels",
    "stylers": [
      {
        "lightness": 50
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels",
    "stylers": [
      {
        "lightness": 50
      }
    ]
  },
  {
    "featureType": "administrative.locality",
    "elementType": "labels",
    "stylers": [
      {
        "lightness": 50
      }
    ]
  },
  {
    "featureType": "administrative.neighborhood",
    "elementType": "labels",
    "stylers": [
      {
        "lightness": 50
      }
    ]
  },
  {
    "featureType": "landscape.man_made",
    "elementType": "labels.text",
    "stylers": [
      {
        "lightness": 50
      }
    ]
  },
  {
    "featureType": "landscape.natural",
    "elementType": "labels",
    "stylers": [
      {
        "lightness": 50
      }
    ]
  },
  {
    "featureType": "poi.attraction",
    "elementType": "labels",
    "stylers": [
      {
        "lightness": 50
      }
    ]
  },
  {
    "featureType": "poi.business",
    "elementType": "labels",
    "stylers": [
      {
        "lightness": 50
      }
    ]
  },
  {
    "featureType": "poi.government",
    "elementType": "labels",
    "stylers": [
      {
        "lightness": 50
      }
    ]
  },
  {
    "featureType": "poi.medical",
    "elementType": "labels",
    "stylers": [
      {
        "lightness": 50
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "saturation": -85
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels",
    "stylers": [
      {
        "lightness": 50
      }
    ]
  },
  {
    "featureType": "poi.place_of_worship",
    "elementType": "labels",
    "stylers": [
      {
        "lightness": 50
      }
    ]
  },
  {
    "featureType": "poi.school",
    "elementType": "labels",
    "stylers": [
      {
        "lightness": 50
      }
    ]
  },
  {
    "featureType": "poi.sports_complex",
    "elementType": "labels",
    "stylers": [
      {
        "lightness": 50
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels",
    "stylers": [
      {
        "lightness": 50
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels",
    "stylers": [
      {
        "lightness": 50
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels",
    "stylers": [
      {
        "lightness": 50
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "labels",
    "stylers": [
      {
        "lightness": 50
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "labels.text",
    "stylers": [
      {
        "lightness": 50
      }
    ]
  }
];

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

class MyFancyComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoveredTree: null,
      hoveredPolygon: null,
      isMarkerShown: false,
      coords: [],
      infos: [],
      treeInfos: [],
      polygonInfos: [],
      zoom: 5,
      center: {lat: 39.50, lng: -98.35},
      trees: [],
      cities: [],
      teams: [],
      polygons: [],
      mapMode: 0,
      bounds: {
        ne: {lat: 37.96688865966527, lng: 41.45780406842232},
        sw: {lat: 23.03085536199719, lng: 95.35073779473305}
      },
      symptomatic: {count: 0, hcount: 0},
      symptomaticVisible: false
    };
    this.handleZoomChanged = debounce(this.handleZoomChanged, 500);
  }

  componentDidMount() {
    const self = this;
    const zoom = this.props.isMobile ? 4 : 5;
    if (!navigator.permissions) {
      navigator.geolocation.getCurrentPosition((data) => {
        self.setState({center: {lat: data.coords.latitude, lng: data.coords.longitude}, zoom})
      })
      return;
    }
    navigator.permissions.query({name: 'geolocation'}).then(function (result) {
      if (result.state === 'granted') {
        navigator.geolocation.getCurrentPosition((data) => {
          self.setState({center: {lat: data.coords.latitude, lng: data.coords.longitude}, zoom})
        })
      } else if (result.state === 'prompt') {
        navigator.geolocation.getCurrentPosition((data) => {
          self.setState({center: {lat: data.coords.latitude, lng: data.coords.longitude}, zoom})
        })
      } else if (result.state === 'denied') {
        self.setState({center: {lat: 30.3753, lng: 69.3451}, zoom})
      }
    });
  }

  getAsymptomatic = (topRight, bottomLeft) => {
    let initObjet = {
      method: "post",
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({deviceId: localStorage.getItem('deviceId'), user: localStorage.getItem('user')})
    };
    let URL = `${process.env.REACT_APP_API_ROOT}/symptomatic?lat1=${bottomLeft.lat()}&lng1=${bottomLeft.lng()}&lat2=${topRight.lat()}&lng2=${topRight.lng()}`;
    fetch(URL, initObjet)
      .then(response => response.json())
      .then(data => {
        this.setState({
          symptomatic: data.data,
          polygons: [],
          polygonInfos: []
        })
      })
      .catch(err => {

      })
  }

  handleZoomChanged = (mapProps, map) => {
    const zoom = map.getZoom();
    let mapMode = 0;
    const bounds = map.getBounds();
    let ne = bounds.getNorthEast();
    let sw = bounds.getSouthWest();
    if (!this.state.symptomaticVisible) {
      if (zoom >= 9) {
        mapMode = 0;
      } else if (zoom < 9) {
        mapMode = 1;
      }
      const initObjet = {
        method: "post",
        headers: {
          Accept: "application/json",
          "content-type": "application/json"
        },
        body: JSON.stringify({
          mapMode: mapMode,
          topRight: ne,
          bottomLeft: sw,
        })
      };
      let URL = `${process.env.REACT_APP_API_ROOT}/near`;
      fetch(URL, initObjet)
        .then(response => response.json())
        .then(data => {
          let polygonInfos = [];
          let polygons = data.data["states"];
          polygons.map(_ => polygonInfos.push(false));
          let maxCases = 0;
          let minCases = Infinity;
          polygons.forEach(({data}) => {
            if (data[data.length - 1].cases > maxCases) {
              maxCases = data[data.length - 1].cases;
            }
            if (data[data.length - 1].cases < minCases) {
              minCases = data[data.length - 1].cases;
            }
          })
          let polygonsArray = [];
          polygons.map((polygon, i) => {
            let radius;
            if (isMobile) {
              if (this.state.zoom >= 9) {
                radius = ((((15000 - 3000) * (polygons[i].data[polygons[i].data.length - 1].cases - minCases)) / (maxCases - minCases)) + 3000) * 6 / zoom;
              } else {
                radius = ((((15000 - 3000) * (polygons[i].data[polygons[i].data.length - 1].cases - minCases)) / (maxCases - minCases)) + 3000) * 30 / zoom;
              }
            } else {
              if (this.state.zoom >= 9) {
                radius = ((((15000 - 3000) * (polygons[i].data[polygons[i].data.length - 1].cases - minCases)) / (maxCases - minCases)) + 3000) * 9 / zoom;
              } else {
                radius = ((((15000 - 3000) * (polygons[i].data[polygons[i].data.length - 1].cases - minCases)) / (maxCases - minCases)) + 3000) * 45 / zoom;
              }
            }
            return polygonsArray.push({
              radius,
              id: polygons[i]._id,
              name: polygons[i].name ? polygons[i].name : polygons[i].combinedKey,
              coords: polygon,
              marker: polygons[i].marker,
              data: polygons[i].data,
            });
          });
          this.setState({
            polygons: polygonsArray,
            polygonInfos
          });
        })
        .catch(err => console.log(err));
      this.setState({
        zoom: map.getZoom(),
        center: {lat: parseFloat(map.getCenter().lat), lng: parseFloat(map.getCenter().lng)}
      });
    } else {
      this.getAsymptomatic(ne, sw);
    }

  };

  handleChange = (e) => {
    this.setState({
      symptomaticVisible: e.target.checked,
      zoom: e.target.checked ? this.state.zoom - 0.001 : this.state.zoom + 0.001
    });
  }

  delayedShowMarker = () => {
    setTimeout(() => {
      this.setState({isMarkerShown: true});
    }, 3000);
  };

  handleHover = (polygon, i) => {
    const data = polygon.data;
    const hoveredPolygon = {
      name: polygon.name,
      deaths: data[data.length - 1].deaths,
      cases: data[data.length - 1].cases,
    };
    this.setState({
      hoveredPolygon: hoveredPolygon,
      polygonInfos: this.state.polygonInfos.map((info, j) =>
        i !== j ? false : true
      )
    });
  };

  handlePolygonClose = i => {
    this.setState({
      polygonInfos: this.state.polygonInfos.map(
        (info, j) => i !== j && false
      )
    });
  };

  render() {
    const {hoveredPolygon} = this.state;
    return (
      <div>
        {this.state.center && (
          <Map
            styles={styles}
            google={window.google}
            className={"map"}
            onZoomChanged={this.handleZoomChanged}
            onCenterChanged={this.handleZoomChanged}
            onBounds_changed={this.handleZoomChanged}
            onReady={this.handleZoomChanged}
            streetViewControl={false}
            fullscreenControl={false}
            mapTypeControl={false}
            initialCenter={{lat: this.state.center.lat, lng: this.state.center.lng}}
            zoom={this.state.zoom}
            yesIWantToUseGoogleMapApiInternals={true}
            containerStyle={{position: 'inherit'}}
          >
            {this.state.polygons.map((polygon, i) => (
              <Circle
                key={polygon.id}
                center={{
                  lat: polygon.marker.coordinates[1],
                  lng: polygon.marker.coordinates[0]
                }}
                radius={polygon.radius}
                fillColor={"#cc0033"}
                strokeColor={"#cc0033"}
                strokeWeight={1}
                onClick={() => this.handleHover(polygon, i)}
              />

            ))}
            {this.state.polygons.map((polygon, i) => (
              <InfoWindow
                key={polygon.id}
                onClose={() => this.handlePolygonClose(i)}
                position={{
                  lat: polygon.marker.coordinates[1],
                  lng: polygon.marker.coordinates[0]
                }}
                visible={this.state.polygonInfos[i]}
              >
                <div id="plantation-infoWindow">
                  <div id="plantation-info-detail">
                    <span id="plantation-tt" style={{fontWeight: 'bold', fontSize: 18, marginBottom: 14}}>
                      {hoveredPolygon ? hoveredPolygon.name : ''}
                    </span>
                    <span id="plantation-tt">
                      <span>
                        <span style={{float: 'left'}}>
                          Reported Cases:{" "}
                        </span>
                        <span style={{
                          float: 'right',
                          fontWeight: 'bold'
                        }}>{hoveredPolygon ? hoveredPolygon.cases : 0}</span>
                      </span>
                    </span>
                    <span id="plantation-tt">
                      <span>
                        <span style={{float: 'left'}}>
                          Deaths:{" "}
                        </span>
                        <span style={{
                          float: 'right',
                          fontWeight: 'bold'
                        }}>{hoveredPolygon ? hoveredPolygon.deaths : 0}</span>
                      </span>
                    </span>
                  </div>
                </div>
              </InfoWindow>
            ))}
          </Map>
        )}
        <div id="asymptomatic">
            {
            this.state.symptomaticVisible ?
            <div style={{
              display: "flex",
              flexDirection: "column",
              minWidth: 200,
              justifyContent: "center",
              // alignItems: "center"
            }}>
                    <span style={{marginBottom: 0, fontSize: '0.9rem'}}><b style={{fontSize:'0.9rem'}}>In this region:</b></span>
                    <span style={{marginBottom: 0, fontSize: '0.9rem'}}><b style={{color: 'green', fontSize:'0.9rem'}}>{this.state.symptomatic.hcount}</b> reported no symptoms</span>
                    <span style={{marginBottom: 0, fontSize: '0.9rem'}}><b style={{color: 'red', fontSize:'0.9rem'}}>{this.state.symptomatic.count}</b> reported symptoms</span>
              </div>
              :
              <div style={{
                display: "flex",
                flexDirection: "column",
                minWidth: 200,
                justifyContent: "center",
                alignItems: "center"
              }}>
              <span style={{fontSize: '1rem'}}>
                    Confirmed Cases
              </span>
              <span style={{fontSize: '0.9rem'}}>
                <b>Toggle</b> to see how people feel
              </span>
              </div>
            }
          <div style={{display: "flex", alignItems: "center", marginLeft: 16}}>
            <AntSwitch checked={this.state.symptomaticVisible} onChange={this.handleChange} name="checked"/>
          </div>

        </div>
      </div>
    );
  }
}

export default withRouter(MyFancyComponent);