import React, {useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import {useTheme} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import Alert from "./Alert";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider,DatePicker } from '@material-ui/pickers';

export default function SurveyTested(props) {
    const [tested, setTested] = useState("");
    const [testResults, setTestResults] = useState("");
    const [testDate, setTestDate] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [error, setError] = useState("");
    const theme = useTheme();

    useEffect(()=>{
        setTested(props.tested);
        setTestResults(props.testResults);
        setTestDate(props.testDate);
    }, [props.testDate, props.tested, props.testResults]);
    const handleNext = () => {
        if (!tested) {
            setError("Tested is required");
            setSnackbarOpen(true);
            return;
        }
        if (tested === "Yes") {
            if (!testResults || testDate === "") {
                setError("Results and test date are required");
                setSnackbarOpen(true);
                return;
            }
        }
        props.handleNext(tested, testResults, testDate);
    };

    const getButtonVariant = ()=> {
        if (tested !== "Yes" && tested) {
            return "contained"
        } else {
            if (testResults && testDate !== "") {
                return "contained"
            }
        }
        return "outlined"
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setError("");
        setSnackbarOpen(false);
    };

    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <Typography variant={"h5"}>
                Have you been tested for COVID-19?
            </Typography>
            <RadioGroup aria-label="tested" name="tested1" value={tested} onChange={(e) => setTested(e.target.value)}>
                <FormControlLabel value="Yes" control={<Radio/>} label="Yes"/>
                <FormControlLabel value="No" control={<Radio/>} label="No"/>
                <FormControlLabel value="Prefer not to say" control={<Radio/>} label="Prefer not to say"/>
            </RadioGroup>
            {tested === "Yes" && <React.Fragment>
                <Divider style={{margin: theme.spacing(1, 0)}}/>
                <Typography variant={"h5"}>Results: </Typography>
                <RadioGroup aria-label="testResults" name="testResults1" value={testResults}
                            onChange={(e) => setTestResults(e.target.value)}>
                    <FormControlLabel value="Yes" control={<Radio/>} label="Positive"/>
                    <FormControlLabel value="No" control={<Radio/>} label="Negative"/>
                    <FormControlLabel value="Prefer not to say" control={<Radio/>} label="Prefer not to say"/>
                </RadioGroup>
                <div>
                    <Typography variant={"h5"} style={{marginTop: theme.spacing(2)}}>When were you tested?</Typography>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            autoOk
                            maxDate={new Date()}
                            value={testDate?testDate:null}
                            label={"Test Date"}
                            format="MM/dd/yyyy"
                            variant="inline"
                            inputVariant="outlined"
                            margin={"normal"}
                            style={{ minWidth: 200 }}
                            InputLabelProps={{ shrink: true }}
                            onChange={(date) => setTestDate(date)}
                        />
                    </MuiPickersUtilsProvider>
                </div>
            </React.Fragment>}
            <div style={{display: "flex", justifyContent: "flex-end", marginTop: theme.spacing(1)}}>
                <Button variant={"outlined"} color={"primary"} onClick={() => props.setStep(0)}>
                    Back
                </Button>
                <Button variant={getButtonVariant()} style={{marginLeft: theme.spacing(2)}} color={"primary"}
                        onClick={() => handleNext()}>
                    Next
                </Button>
            </div>
            <Snackbar open={snackbarOpen} onClose={handleClose} autoHideDuration={3000}>
                <Alert severity={"error"}>{error}</Alert>
            </Snackbar>
        </div>
    )
}